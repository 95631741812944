var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "90%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Add your Deposit Address,UID to receive $GDV")))]), _c('div', {
    staticClass: "pop-content"
  }, [_c('div', {
    staticClass: "content__tit",
    on: {
      "click": function ($event) {
        return _vm.openView();
      }
    }
  }, [_vm._v(" Where to find it?")]), _c('div', {
    staticClass: "content__input"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" Bitget Exchange UID "), _c('div', {
    staticClass: "bt",
    on: {
      "click": _vm.openBitget
    }
  }, [_vm._v("Register Bitget")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.uid,
      expression: "uid"
    }],
    attrs: {
      "type": "number",
      "disabled": _vm.isDisabled,
      "placeholder": "Bitget Exchange UID"
    },
    domProps: {
      "value": _vm.uid
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.uid = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "content__input"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" $GDV Deposit address ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.address,
      expression: "address"
    }],
    attrs: {
      "type": "text",
      "disabled": _vm.isDisabled,
      "placeholder": "$GDV Deposit address"
    },
    domProps: {
      "value": _vm.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.address = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "content__condition",
    class: _vm.$parent.registerTask.completed && 'content__condition_1'
  }, [!_vm.$parent.registerTask.completed ? [_vm._v(" Please fill in the correct imformation, otherviese you may not receive the airdrop ")] : [_vm._v(" Deposit to Bitget is in progress. $GDV will be credited to your Bitget spot account before 9th September. ")]], 2), _vm.$parent.registerTask.completed == 0 ? _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.confirmFn
    }
  }, [_vm._v(_vm._s(_vm.$lang("Submit")))])]) : _vm._e()])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };