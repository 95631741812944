import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "bitgetwallet page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "invite-btns"
  }, [_c('div', {
    staticClass: "btn btn-i",
    on: {
      "click": _vm.onShare
    }
  }, [_vm._v(_vm._s(_vm.$lang('Invite a friend')))]), _c('div', {
    directives: [{
      name: "copy",
      rawName: "v-copy",
      value: `${_vm.invitation_tip}\nhttps://t.me/${_vm.setting.botname}/app?startapp=ref_${_vm.userData.ref_code}`,
      expression: "`${invitation_tip}\\nhttps://t.me/${setting.botname}/app?startapp=ref_${userData.ref_code}`"
    }],
    staticClass: "btn btn-copy"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-copy"
    }
  })], 1)]), _c('div', {
    staticClass: "content-box"
  }, [_c('div', {
    staticClass: "content-box-num"
  }, [_vm._v("1")]), _c('div', {
    staticClass: "content-box-img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/icon-frens.png`)
    }
  })]), _c('div', {
    staticClass: "content-box-desc"
  }, [_vm._v(" Invite friends to join tapcoins and get keys ")]), _c('div', {
    staticClass: "content-box-items"
  }, _vm._l(_vm.rewardsInfo.inviteRewards, function (item, index) {
    return index < 3 ? _c('div', {
      staticClass: "content-box-item",
      on: {
        "click": function ($event) {
          _vm.rewardsInfo.inviteCount >= item.count && _vm.onReceive(item, 0, index);
        }
      }
    }, [_c('div', {
      staticClass: "item-title"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/earn-key.png`)
      }
    }), _vm._v(" +" + _vm._s(item.stocks))]), _c('div', {
      staticClass: "item-brief"
    }, [_c('b', [_vm._v("+" + _vm._s(item.count))]), _vm._v(" Friends")]), _c('div', {
      staticClass: "item-bg",
      style: {
        height: `${item.progress * 100}%`
      }
    }), _c('div', {
      staticClass: "item-bg1"
    }), item.received ? _c('div', {
      staticClass: "item-check"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/airdrop-ok.png`)
      }
    })]) : _vm._e()]) : _vm._e();
  }), 0), _c('div', {
    staticClass: "content-box-items"
  }, _vm._l(_vm.rewardsInfo.inviteRewards, function (item, index) {
    return index >= 3 ? _c('div', {
      staticClass: "content-box-item",
      on: {
        "click": function ($event) {
          _vm.rewardsInfo.inviteCount >= item.count && _vm.onReceive(item, 0, index);
        }
      }
    }, [_c('div', {
      staticClass: "item-title"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/earn-key.png`)
      }
    }), _vm._v(" +" + _vm._s(item.stocks))]), _c('div', {
      staticClass: "item-brief"
    }, [_c('b', [_vm._v("+" + _vm._s(item.count))]), _vm._v(" Friends")]), _c('div', {
      staticClass: "item-bg",
      style: {
        height: `${item.progress * 100}%`
      }
    }), _c('div', {
      staticClass: "item-bg1"
    }), item.received ? _c('div', {
      staticClass: "item-check"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/airdrop-ok.png`)
      }
    })]) : _vm._e()]) : _vm._e();
  }), 0)]), _c('div', {
    staticClass: "content-box"
  }, [_c('div', {
    staticClass: "content-box-num"
  }, [_vm._v("2")]), _vm._m(1), _c('div', {
    staticClass: "content-box-desc"
  }, [_vm._v(" After your friend registers Biomatrix and submits the code, you can get the keys ")]), _c('div', {
    staticClass: "content-box-items"
  }, _vm._l(_vm.rewardsInfo.inviteAirdropRewards, function (item, index) {
    return index < 3 ? _c('div', {
      staticClass: "content-box-item",
      on: {
        "click": function ($event) {
          _vm.rewardsInfo.inviteeRewardCount >= item.count && _vm.onReceive(item, 1, index);
        }
      }
    }, [_c('div', {
      staticClass: "item-title"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/earn-key.png`)
      }
    }), _vm._v(" +" + _vm._s(item.stocks))]), _c('div', {
      staticClass: "item-brief"
    }, [_c('b', [_vm._v("+" + _vm._s(item.count))]), _vm._v(" Verified")]), _c('div', {
      staticClass: "item-bg",
      style: {
        height: `${item.progress * 100}%`
      }
    }), _c('div', {
      staticClass: "item-bg1"
    }), item.received ? _c('div', {
      staticClass: "item-check"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/airdrop-ok.png`)
      }
    })]) : _vm._e()]) : _vm._e();
  }), 0), _c('div', {
    staticClass: "content-box-items"
  }, _vm._l(_vm.rewardsInfo.inviteAirdropRewards, function (item, index) {
    return index >= 3 ? _c('div', {
      staticClass: "content-box-item",
      on: {
        "click": function ($event) {
          _vm.rewardsInfo.inviteeRewardCount >= item.count && _vm.onReceive(item, 1, index);
        }
      }
    }, [_c('div', {
      staticClass: "item-title"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/earn-key.png`)
      }
    }), _vm._v(" +" + _vm._s(item.stocks))]), _c('div', {
      staticClass: "item-brief"
    }, [_c('b', [_vm._v("+" + _vm._s(item.count))]), _vm._v(" Verified")]), _c('div', {
      staticClass: "item-bg",
      style: {
        height: `${item.progress * 100}%`
      }
    }), _c('div', {
      staticClass: "item-bg1"
    }), item.received ? _c('div', {
      staticClass: "item-check"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/airdrop-ok.png`)
      }
    })]) : _vm._e()]) : _vm._e();
  }), 0)]), _c('div', {
    staticClass: "content-box content-box-a"
  }, [_c('div', {
    staticClass: "content-box-num"
  }, [_vm._v("3")]), _c('div', {
    staticClass: "content-box-img",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/airdropReward?id=1');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/icons/airdorp-usdt-bg.png?v=1"
    }
  })]), _c('div', {
    staticClass: "content-box-brief"
  }, [_c('div', {
    staticClass: "brief-t"
  }, [_vm._v(" Rule "), _c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/icons/tonview.png"
    },
    on: {
      "click": _vm.tonView
    }
  })]), _c('div', {
    staticClass: "brief-b"
  }, [_vm._v(" 1.After completing the registration verification, you can receive the airdrop reward ")]), _c('div', {
    staticClass: "brief-b"
  }, [_vm._v(" 2.When your friends claim the airdrop, you will also receive a 0.1USDT reward ")])]), _c('div', {
    staticClass: "content-box-btn",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/airdropReward?id=1');
      }
    }
  }, [_vm._v(" Receive ")])]), _c('div', {
    staticClass: "fs"
  }, [_c('div', {
    staticClass: "fs__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Ranking")) + " ")]), _c('div', {
    staticClass: "fs-list"
  }, [_vm._l(_vm.recordList, function (item, index) {
    return _c('div', {
      staticClass: "fs-item"
    }, [_c('div', {
      staticClass: "item-icon"
    }, [index === 0 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-1.png`),
        "alt": ""
      }
    }) : index === 1 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-2.png`),
        "alt": ""
      }
    }) : index === 2 ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-3.png`),
        "alt": ""
      }
    }) : _c('span', [_vm._v(_vm._s(index + 1))])]), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.user_firstname) + " " + _vm._s(item.user_lastname))])]), _c('div', {
      staticClass: "op"
    }, [_c('img', {
      attrs: {
        "src": "https://static.tapcoins.app/icons/usdt.png"
      }
    }), _vm._v(" "), _c('span', [_vm._v("+" + _vm._s(_vm._f("nFormatter")(item.reward_amount, 2)))])])]);
  }), _vm.recordLoading ? [_c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)])] : _vm._e()], 2)])]), _c('pop-get-reward-tip', {
    attrs: {
      "show": _vm.popGetRewardTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popGetRewardTipShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "top-box"
  }, [_c('div', {
    staticClass: "top-box-logo"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/20240713/c02bee42-7541-40b0-9d77-2771af4c6ec7.png"
    }
  })]), _c('div', {
    staticClass: "top-box-title"
  }, [_vm._v(" BioMatrix Friend Summon ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-box-img"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/20240713/c02bee42-7541-40b0-9d77-2771af4c6ec7.png"
    }
  })]);
}];
export { render, staticRenderFns };