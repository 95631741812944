var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "airdropTap page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "calendar-box"
  }, [_c('div', {
    staticClass: "item item-gray"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })], 1), _c('div', {
    staticClass: "m"
  }, [_vm._v("AUG")]), _c('div', {
    staticClass: "d"
  }, [_vm._v("20th")])]), _c('div', {
    staticClass: "item item-gray"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })], 1), _c('div', {
    staticClass: "m"
  }, [_vm._v("AUG")]), _c('div', {
    staticClass: "d"
  }, [_vm._v("20th")])]), _c('div', {
    staticClass: "item item-gray"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })], 1), _c('div', {
    staticClass: "m"
  }, [_vm._v("AUG")]), _c('div', {
    staticClass: "d"
  }, [_vm._v("20th")])]), _c('div', {
    staticClass: "item item-dblue"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-sigh"
    }
  })], 1), _c('div', {
    staticClass: "m"
  }, [_vm._v("AUG")]), _c('div', {
    staticClass: "d"
  }, [_vm._v("20th")])]), _vm._m(2), _vm._m(3), _vm._m(4)])]), _c('pop-get-reward-tip', {
    attrs: {
      "show": _vm.popGetRewardTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popGetRewardTipShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "top-box"
  }, [_c('div', {
    staticClass: "top-box-title"
  }, [_vm._v(" Airdrop ")]), _c('div', {
    staticClass: "top-box-num"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/icons/tap.png"
    }
  }), _vm._v(" +123123123 "), _c('span', [_vm._v("$Tap")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn"
  }, [_vm._v(" Claim ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/icons/tap.png"
    }
  })]), _c('div', {
    staticClass: "m"
  }, [_vm._v("AUG")]), _c('div', {
    staticClass: "d"
  }, [_vm._v("20th")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/icons/tap.png"
    }
  })]), _c('div', {
    staticClass: "m"
  }, [_vm._v("AUG")]), _c('div', {
    staticClass: "d"
  }, [_vm._v("20th")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/icons/tap.png"
    }
  })]), _c('div', {
    staticClass: "m"
  }, [_vm._v("AUG")]), _c('div', {
    staticClass: "d"
  }, [_vm._v("20th")])]);
}];
export { render, staticRenderFns };