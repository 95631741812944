import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "airdropTap",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      isFlag: true
    };
  },
  beforeRouteLeave(to, from, next) {
    this.isFlag = true;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {},
  methods: {
    ...userVuex.mapMutations(['setUserData'])
  }
};