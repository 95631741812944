import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "activity",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  methods: {
    jumpTo(url) {
      this.$router.push(url);
    }
  }
};