var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lucky"
  }, [_c('div', {
    staticClass: "lucky-main"
  }, [_c('div', {
    ref: "resultCoinCoincotainerRef",
    staticClass: "luckyTable-coin-coincotainer"
  }), _c('div', {
    staticClass: "lucky-con",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/lucky/lucky-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "luckyTable"
  }, [_c('div', {
    staticClass: "luckyTable-m"
  }), _c('div', {
    staticClass: "luckyTable-bg",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/lucky/lucky-item-bg.png`)})`
    }
  }), _c('div', {
    ref: "luckyTableAdroinRef",
    staticClass: "luckyTable-adroin",
    class: _vm.isStartLucky && 'active',
    style: {
      backgroundImage: `url(${require(`@images/${this.theme}/lucky/light1.png`)})`
    }
  }), _vm.isLoadingLuckyList ? _c('div', {
    staticClass: "luckyTable-loading"
  }) : [_vm.prizeList && _vm.prizeList.length > 0 ? _c('div', {
    staticClass: "luckyTable-main",
    attrs: {
      "id": "luckyTableMain"
    }
  }, [_c('lucky-wheel', {
    ref: "vueLuckywheel",
    attrs: {
      "prize-index": _vm.prizeIndex
    },
    on: {
      "get-prize": _vm.onStart,
      "game-over": _vm.onResult
    }
  }, _vm._l(_vm.prizeList, function (prize, index) {
    return _c('lucky-wheel-item', {
      key: index
    }, [_c('div', {
      staticClass: "luckyTable-item"
    }, [_c('div', {
      staticClass: "luckyTable__num"
    }, [_vm._v(_vm._s(_vm._f("toPre")(prize.value, 2, true, true)))]), _c('div', {
      staticClass: "luckyTable__unit"
    }, [_vm._v(_vm._s(prize.unit))]), _c('div', {
      staticClass: "luckyTable__icon",
      style: {
        backgroundImage: `url(${require(`@images/${_vm.theme}/lucky/${prize.type}.png`)})`
      }
    })])]);
  }), 1)], 1) : _vm._e()]], 2)]), _c('div', {
    staticClass: "lucky-btns"
  }, [_c('div', {
    staticClass: "lucky-balance"
  }, [_c('div', {
    staticClass: "lucky-balance-item key"
  }, [_c('div', {
    staticClass: "lucky-balance-item__icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/lucky/key.png`)})`
    }
  }), _c('div', {
    staticClass: "lucky-balance-item__num"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.userData.stock, 2, true, true)))])]), _c('div', {
    staticClass: "lucky-balance-item"
  }, [_c('div', {
    ref: "usdtBalanceRef",
    staticClass: "lucky-balance-item__icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/lucky/usdt.png`)})`
    }
  }), _c('div', {
    staticClass: "lucky-balance-item__num"
  }, [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.assetsInfo.usdt, 2, true, true)) + " ")])]), _c('div', {
    staticClass: "lucky-balance-item"
  }, [_c('div', {
    ref: "tapcoinsBalanceRef",
    staticClass: "lucky-balance-item__icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/lucky/tapcoins.png`)})`
    }
  }), _c('div', {
    staticClass: "lucky-balance-item__num"
  }, [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.userData.coin, 2, true, true)) + " ")])])]), _c('div', {
    staticClass: "lucky-btn",
    class: {
      'lucky-btn-gray': !_vm.isFlag
    },
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/lucky/lucky-btn-bg.png`)})`
    },
    on: {
      "click": _vm.onStart
    }
  }, [_c('div', {
    staticClass: "lucky-btn-main"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/key.png`),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm._f("toPre")(_vm.luckyInfo.price_stock, 2, true, true)) + " + "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/tapcoins.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toPre")(_vm.luckyInfo.price_coin, 2, true, true)))])])]), _c('div', {
    staticClass: "record"
  }, [_c('div', {
    staticClass: "record__title"
  }, [_vm._v(_vm._s(_vm.$lang("Records")))]), _c('div', {
    staticClass: "record-list"
  }, [_vm.luckyLoading ? [_c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)])] : _vm._e(), !_vm.luckyLogs ? [_c('div', {
    staticClass: "empty"
  }, [_vm._v(_vm._s(_vm.$lang("There's nothing here")))])] : _vm._l(_vm.luckyLogs, function (item, index) {
    return _c('div', {
      staticClass: "record-item"
    }, [_c('div', {
      staticClass: "record-item__icon",
      style: {
        backgroundImage: `url(${require(`@images/${_vm.theme}/lucky/${item.item_currency.toLowerCase()}.png`)})`
      }
    }), _c('div', {
      staticClass: "record-item__num"
    }, [_vm._v(" " + _vm._s(_vm._f("toPre")(item.item_amount, 2, true, true)) + " "), _c('span', [_vm._v(_vm._s(item.item_currency))])])]);
  })], 2)])]), _c('pop-reward-result', {
    ref: "popRewardResultRef",
    attrs: {
      "coinRef": _vm.coinResultRef,
      "show": _vm.isPopResult,
      "select-row": _vm.selectRow,
      "title": _vm.$lang('Congratulations on getting')
    },
    on: {
      "update:show": function ($event) {
        _vm.isPopResult = $event;
      },
      "accept": _vm.onAccept
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };