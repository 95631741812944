var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "activity page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "activity-main"
  }, [_c('div', {
    staticClass: "activity-top"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/activity-top.png`)
    }
  })]), _c('div', {
    staticClass: "activity-desc"
  }, [_vm._v(" Earn " + _vm._s(_vm.setting.coin) + ", Keys, USDT, TON and more ")]), _c('div', {
    staticClass: "activity-list"
  }, [_c('div', {
    staticClass: "activity-item",
    on: {
      "click": function ($event) {
        return _vm.jumpTo('/lucky');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/taskimg/luckylottery.jpg?v=1"
    }
  })])])])]), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };