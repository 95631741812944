/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AirdropTap.vue?vue&type=template&id=c25d3060&scoped=true"
import script from "./AirdropTap.vue?vue&type=script&lang=js"
export * from "./AirdropTap.vue?vue&type=script&lang=js"
import style1 from "./AirdropTap.vue?vue&type=style&index=1&id=c25d3060&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c25d3060",
  null
  
)

export default component.exports